import React, {useEffect, useRef, useState} from 'react';
import { useFrame } from '@react-three/fiber';
import { Html, useGLTF, useTexture } from '@react-three/drei';
import styles from 'styles/Mug.module.css';
import { texturesOptions } from 'lib/texturesOptions';

function Mug({ preview = false, productId, defaultProductId, isDefaultProduct, isMobile, swipeIcon, ...props }) {
    const ref = useRef();
    const { nodes, materials } = useGLTF('/assets/model/mug.glb');
    // const [textureActive, setTextureActive] = useState(0);
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    const [isAdded, setIsAdded] = useState(false); // Stato per la notifica di conferma

    const roughnessTexture = useTexture(`/assets/textures/roughness.png`, texture => {
        texture.flipY = false;
    });

    const texture = useTexture('https://cdn.mugmakershop.com/static/' + productId + '.jpg', texture => {
        texture.flipY = false;
    });
    // const texture = useTexture(`/assets/textures/${texturesOptions[textureActive].file}`, texture => {
    //     texture.flipY = false;
    // });

    // rotation animation
    useFrame((state, delta) => {
        if (!preview) {
            const time = state.clock.getElapsedTime();
            ref.current.rotation.x = Math.cos(time / 2) / 6;
            ref.current.rotation.y = Math.sin(time / 2) / 6;
            ref.current.position.y = Math.sin(time / 1.5) / 10;
            ref.current.rotation.z = -0.1 - (Math.sin(time / 1.5)) / 20;
        }
    });

    useEffect(() => {
      const updateHeight = () => {
        const canvas = document.querySelector('canvas');
        if (canvas) {
          const boundingRect = canvas.getBoundingClientRect();
          // const aspectRatio = 16 / 9; // Usa il rapporto del tuo canvas (es. 16:9)
          // const calculatedHeight = boundingRect.width / aspectRatio;
          // window.parent.postMessage({ action: 'resizeIframe', height: calculatedHeight }, '*');
          window.parent.postMessage({ action: 'resizeIframe', width: boundingRect.width }, '*');
          // const calculatedHeight = boundingRect.width / aspectRatio;
          // window.parent.postMessage({ action: 'resizeIframe', height: calculatedHeight }, '*');
        }
      };

      // Aggiorna l'altezza dopo un breve ritardo per garantire che il canvas sia pronto
      setTimeout(updateHeight, 100); // Ritardo iniziale di 100ms
      window.addEventListener('resize', updateHeight); // Ascolta i resize successivi

      return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const handleGlobalInteraction = () => {
        setShowPlaceholder(false);
        window.removeEventListener('pointerdown', handleGlobalInteraction);
    };

    // Listener globale per qualsiasi clic o tocco
    window.addEventListener('pointerdown', handleGlobalInteraction);

    // Funzione per inviare un messaggio alla pagina principale per l'aggiunta al carrello
    const addToCart = () => {
        if (productId) {
            window.parent.postMessage(
                {
                    action: 'addToCart',
                    productId: 49330383290715,
                    quantity: 1,
                    properties: {
                        'Design No.': productId, // Il tuo campo personalizzato
                    },
                },
                '*'
            );
            // Mostra la conferma di aggiunta e nascondila dopo 2 secondi
            setIsAdded(true);
            setTimeout(() => setIsAdded(false), 5000);
            console.warn('ID prodotto: ' + productId);
        } else {
            // Mostra la conferma di aggiunta e nascondila dopo 2 secondi
            setIsAdded(true);
            setTimeout(() => setIsAdded(false), 5000);
            console.warn('ID prodotto non trovato.');
        }
    };

    return (
        <group {...props} dispose={null}>
            <mesh
                geometry={nodes['Mug'].geometry}
                material={materials['Material']}
                material-map={texture}
                material-roughness={1}
                material-roughnessMap={roughnessTexture}
                ref={ref}
            >
                {!preview && productId !== defaultProductId && (
                    <Html position={isMobile ? [0, 8.2, 0] : [-8, 0, 0]}
                          transform
                          occlude
                    >
                        {isAdded && (
                            <div className={styles.confirmationMessage}>
                                Aggiunto al Carrello! <span className={styles.thumbIcon}>👍</span>
                            </div>
                        )}

                        {!isAdded && (
                            <div className={styles.priceContainer}>
                                <span className={styles.priceTag}>€14,95</span> {/* Prezzo del prodotto */}
                                <button
                                    className={styles.addToCartButton}
                                    onClick={addToCart}
                                >
                                    Aggiungi al Carrello
                                </button>
                            </div>
                        )}
                    </Html>
                )}

                {swipeIcon && (
                    <Html>
                        <div className={styles.placeholderContainer}>
                            <div className={styles.swipeAnimation}>
                                <img
                                    src="/assets/icons/hand-swipe.svg"
                                    alt="Hand Swipe"
                                    className={`${styles.handIcon} ${!showPlaceholder ? styles.hidden : ''}`}
                                />
                            </div>
                        </div>
                    </Html>
                )}
            </mesh>
        </group>
    )
}

export {Mug}