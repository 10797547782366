import 'App.css';
import { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Importa React Router
import { Canvas } from '@react-three/fiber';
import { ContactShadows, Environment, PresentationControls } from '@react-three/drei';
import { AiFillGithub } from 'react-icons/ai';
import { Mug } from 'components/Mug';
import Editor from 'components/Editor'; // Importa il tuo componente Editor
import Loading from 'components/Loading';
import { useIsMobile } from 'hooks/useIsMobile';
import { useEffect, useRef } from 'react';
import axios from "axios";

function App() {
  const isMobile = useIsMobile();
  const divRef = useRef();
  // SET DEFAULT TO AVOID ERRORS (PLACEHOLDER)
  const defaultProductId = process.env.REACT_APP_DEFAULT_PRODUCT_ID;
  const [productId, setProductId] = useState(defaultProductId);
  const [isDefaultProduct, setIsDefaultProduct] = useState(true);
  const [isPublishing, setIsPublishing] = useState(false);
  const [galleryIds, setGalleryIds] = useState("");

    const publishMug = async () => {
        setIsPublishing(true);
        try {
            const dataURL = await generateScreenshot(false);
            const base64Image = dataURL.split(',')[1]; // Rimuovi il prefisso "data:image/jpeg;base64,"

            // Effettua la chiamata POST
            const response = await axios.post(
            "https://cdn.mugmakershop.com/publish-mug",
            {
              image: base64Image,
              texture_id: productId,
              gallery_ids: galleryIds.split(",").map((id) => id.trim()),
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer rOD4hdQk5ImDxmBPgOx03a55PjBtpYt6QPXzEvTZcIAmr51vlmzeakbtVbhMj7JC"
              }
            }
            );

            if (response.status === 200) {
                console.log('Pubblicazione riuscita!');
                alert('Tazza pubblicata con successo!');
            } else {
                console.error('Errore durante la pubblicazione:', response.statusText);
                alert('Errore durante la pubblicazione. Riprova.');
            }
        } catch (error) {
            console.error('Errore durante la pubblicazione:', error);
            alert('Errore durante la pubblicazione. Controlla la console per maggiori dettagli.');
        } finally {
            setIsPublishing(false);
        }
    };

    const generateScreenshot = async (transparent = false)=> {
     const canvasElement = document.querySelector('canvas'); // Individua il canvas WebGL
      if (!canvasElement) {
        console.error('Canvas non trovato!');
        return;
      }

      try {
        // Aspetta un ciclo del rendering per assicurarti che sia completo
        await new Promise((resolve) => setTimeout(resolve, 100));

        // Controlla se il canvas è "tainted"
        try {
          // Crea un nuovo canvas temporaneo
          const tempCanvas = document.createElement('canvas');
          const context = tempCanvas.getContext('2d');

          // Imposta le dimensioni del nuovo canvas uguali al canvas originale
          tempCanvas.width = canvasElement.width;
          tempCanvas.height = canvasElement.height;

          if (!transparent) {
            // Disegna uno sfondo bianco solo se il parametro `transparent` è falso
            context.fillStyle = 'white';
            context.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
          }

          // Disegna il contenuto del canvas originale sopra lo sfondo bianco
          context.drawImage(canvasElement, 0, 0);

          // Esporta l'immagine nel formato appropriato
          const format = transparent ? 'image/png' : 'image/jpeg';
          const quality = transparent ? undefined : 0.9; // Solo JPEG usa qualità
          return tempCanvas.toDataURL(format, quality);
        } catch (error) {
          console.error('Errore: il canvas potrebbe essere contaminato (tainted). Verifica CORS.', error);
        }
      } catch (error) {
        console.error('Errore durante lo screenshot:', error);
      }
    }

    const takeScreenshot = async () => {
      const dataURL = await generateScreenshot(false);
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'mugmakershop-screenshot.jpg'; // Estensione .jpg
      link.click();
    };

    const takeScreenshotTransparent = async () => {
      const dataURL = await generateScreenshot(true); // Screenshot trasparente
      if (dataURL) {
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'mugmakershop-screenshot.png'; // Estensione .png
        link.click();
      }
    };

  // Funzione per gestire i cookie
  const setCookie = (name, value, days) => {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `${name}=${value};${expires};path=/;SameSite=None;Secure`;
  };

  const getCookie = (name) => {
      const nameEQ = name + '=';
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i];
          while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
          if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length);
      }
      return null;
  };

  // Estrai l'ID del prodotto dai query params o dai cookie
  useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const idFromParams = urlParams.get('p');
      if (idFromParams) {
          // console.log('ID prodotto: ' + idFromParams);
          setProductId(idFromParams);
          setCookie('productId', idFromParams, 30);
      } else {
          const idFromCookie = getCookie('productId');
          if (idFromCookie) {
              // console.log('ID prodotto: ' + idFromCookie);
              setProductId(idFromCookie);
          }
      }

      const savedGalleryIds = getCookie("publisherGalleryIds");
      if (savedGalleryIds) {
        setGalleryIds(savedGalleryIds);
      }
  }, []);

  useEffect(() => {
    // Esegui il controllo ogni volta che productId cambia
    setIsDefaultProduct(productId === defaultProductId);
  }, [productId, defaultProductId]);

  useEffect(() => {
    setCookie("publisherGalleryIds", galleryIds, 30);
  }, [galleryIds]);

  const getMugPosition = (path) => {
    if (path === 'homepage' || path === null) {
        if (isDefaultProduct || !isMobile) {
            return [0, 0.5, 0];
        } else {
            return [0, -1.5, 0];
        }
    }
    if (path === 'preview') {
      return [0, 1.2, 0];
    }
    if (path === 'cms') {
      return [0, 0.4, 0];
    }
    if (path === 'product') {
      return [0, 0, 0];
    }
    return [0, 0, 0]; // Default position
  };

  function ExternalRedirect({ to }) {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null; // Puoi aggiungere un loading spinner o un messaggio qui se necessario
  }

  return (
    <Router>
        <div className="App">
            <Routes>
                <Route path="/" element={<ExternalRedirect to="https://www.mugmakershop.com"/>}/>
                <Route
                    path="/homepage"
                    element={
                        <Suspense fallback={<Loading/>}>
                            <Canvas camera={{position: [0, 0, 23], fov: 45, near: 0.1, far: 1000}}
                                    style={{touchAction: 'none', width: '100vw'}}>
                                <ambientLight intensity={0.1}/>
                                <spotLight position={[10, 10, 10]} intensity={600} angle={0.45} penumbra={1}
                                           castShadow/>
                                <Environment files="/assets/hdr/potsdamer_platz_1k.hdr"/>
                                <PresentationControls
                                    global
                                    config={{mass: 2, tension: 500}}
                                    snap={{mass: 4, tension: 1500}}
                                    rotation={[0, 0.2, 0]}
                                    polar={[-Math.PI / 3, Math.PI / 3]}
                                    azimuth={[-Math.PI, Math.PI]}>
                                    <Mug position={getMugPosition("homepage")} productId={productId}
                                         defaultProductId={defaultProductId} isMobile={isMobile}/>
                                </PresentationControls>
                                <ContactShadows position={isMobile ? [0, -8.7, 0] : [0, -7.5, 0]} opacity={0.55}
                                                width={10} height={10} blur={2.5} far={20}/>
                            </Canvas>
                        </Suspense>
                    }
                />
                <Route
                    path="/preview"
                    element={
                        <Suspense fallback={<Loading/>}>
                            <Canvas camera={{position: [0, 0, 25], fov: 45}}
                                    style={{touchAction: 'none', width: '100vw'}}>
                                <ambientLight intensity={0.1}/>
                                <spotLight position={[10, 10, 10]} intensity={600} angle={0.45} penumbra={1}
                                           castShadow/>
                                <Environment files="/assets/hdr/potsdamer_platz_1k.hdr"/>
                                <PresentationControls
                                    global
                                    config={{mass: 2, tension: 500}}
                                    rotation={[0, 0.2, 0]}
                                    polar={[-Math.PI / 3, Math.PI / 3]}
                                    azimuth={[-Math.PI, Math.PI]}>
                                    <Mug position={getMugPosition("preview")} preview={true} productId={productId}
                                         defaultProductId={defaultProductId} isMobile={isMobile}/>
                                </PresentationControls>
                                <ContactShadows position={isMobile ? [0, -8.7, 0] : [0, -7.5, 0]} opacity={0.55}
                                                width={10} height={10} blur={2.5} far={20}/>
                            </Canvas>
                        </Suspense>
                    }
                />
                <Route
                    path="/cms"
                    element={
                        <>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 'calc(50% - 550px)',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)', // Centro esatto
                                    zIndex: 1000,
                                    display: 'flex',
                                    gap: '20px', // Spaziatura tra i pulsanti
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <button
                                    onClick={() => window.location.href = '/v1?admin=1'}
                                    className="button"
                                >
                                    Editor
                                </button>
                                <button
                                    onClick={takeScreenshot}
                                    className="button"
                                >
                                    Screenshot
                                </button>
                                <button
                                    onClick={takeScreenshotTransparent}
                                    className="button"
                                >
                                    Screenshot PNG
                                </button>
                                <button
                                    onClick={publishMug}
                                    disabled={isPublishing}
                                    style={{
                                        padding: '10px 20px',
                                        backgroundColor: isPublishing ? '#6c757d' : '#28a745', // Cambia colore se disabilitato
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: isPublishing ? 'not-allowed' : 'pointer', // Cambia cursore se disabilitato
                                        opacity: isPublishing ? 0.7 : 1, // Riduci opacità se disabilitato
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '10px',
                                        minWidth: '150px', // Imposta una larghezza minima
                                    }}
                                >
                                    {isPublishing ? (
                                        <>
                                            <span>Loading...</span>
                                            <div
                                                style={{
                                                    width: '16px',
                                                    height: '16px',
                                                    border: '2px solid #fff',
                                                    borderTop: '2px solid transparent',
                                                    borderRadius: '50%',
                                                    animation: 'spin 1s linear infinite',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        'Publish'
                                    )}
                                </button>
                                <input
                                    type="text"
                                    placeholder="Gallery IDs (es. 1,2,3)"
                                    value={galleryIds}
                                    onChange={(e) => setGalleryIds(e.target.value)} // Aggiorna lo stato
                                    style={{
                                        padding: "10px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                ref={divRef}
                                style={{
                                    width: '100vw',
                                    height: '100vh',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                            <div
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        outline: '4px solid black', // Bordo nero intorno al canvas
                                        outlineOffset: '-5px', // Offset per farlo sembrare un bordo
                                    }}
                                >
                                    <Suspense fallback={<Loading/>}>
                                        <Canvas camera={{position: [0, 0, 21], fov: 40}}
                                                style={{touchAction: 'none', width: '1000px', height: '1000px'}}
                                                gl={{preserveDrawingBuffer: true}}>
                                            <ambientLight intensity={0.6}/>
                                            <spotLight position={[10, 10, 10]} intensity={600} angle={0.60} penumbra={1}
                                                       castShadow/>
                                            <Environment files="/assets/hdr/potsdamer_platz_1k.hdr"/>
                                            <PresentationControls
                                                global
                                                config={{mass: 2, tension: 500}}
                                                rotation={[0, 0.2, 0]}
                                                polar={[-Math.PI / 3, Math.PI / 3]}
                                                azimuth={[-Math.PI, Math.PI]}>
                                                <Mug position={getMugPosition("cms")} preview={true}
                                                     productId={productId}
                                                     defaultProductId={defaultProductId} isMobile={isMobile}/>
                                            </PresentationControls>
                                            <ContactShadows position={isMobile ? [0, -8.7, 0] : [0, -7.5, 0]}
                                                            opacity={0.25}
                                                            width={10} height={10} blur={2.5} far={20}/>
                                        </Canvas>
                                    </Suspense>
                                </div>
                            </div>
                        </>
                    }
                />
                <Route
                    path="/product"
                    element={
                        <Suspense fallback={<Loading/>}>
                            <Canvas camera={{position: [0, 0, 18], fov: 45}}
                                    style={{touchAction: 'none', width: '100vw'}}>
                                <ambientLight intensity={0.1}/>
                                <spotLight position={[10, 10, 10]} intensity={600} angle={0.45} penumbra={1}
                                           castShadow/>
                                <Environment files="/assets/hdr/potsdamer_platz_1k.hdr"/>
                                <PresentationControls
                                    global
                                    config={{mass: 2, tension: 500}}
                                    rotation={[0, 0.2, 0]}
                                    polar={[-Math.PI / 3, Math.PI / 3]}
                                    azimuth={[-Math.PI, Math.PI]}>
                                    <Mug position={getMugPosition("product")} preview={true} productId={productId}
                                         defaultProductId={defaultProductId} isMobile={isMobile} swipeIcon={true}/>
                                </PresentationControls>
                                {/*<ContactShadows position={isMobile ? [0, -8.7, 0] : [0, -7.5, 0]} opacity={0.55} width={10} height={10} blur={2.5} far={20} />*/}
                            </Canvas>
                        </Suspense>
                    }
                />
                <Route path="/v1" element={<Editor/>}/>
            </Routes>
        </div>
    </Router>
  );
}

export default App;
